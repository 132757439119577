import * as React from "react"

const Lunch2 = () => (
  <div className="text-xl">
    <p>
      <em className="text-2xl">109 kr</em>
    </p>
    <p>
      Onsdag - fredag <em>11:30 - 14:30</em>
    </p>

    <p>
      <b>Onsdag</b>
      <br />
      Fläsknoisette med madeirastuvade champinjoner
      <br />
      Stekt koljafilé med gräslökssås
      <br />
      Pasta med kassler & tomat- & gräddsås
    </p>

    <p>
      <b>Torsdag</b>
      <br />
      Biff Stroganoff
      <br />
      Stekt kummelfilé med dijonhollandaisesås
      <br />
      Pasta med fläskytterfilé, pesto & grädde
    </p>

    <p>
      <b>Fredag</b>
      <br />
      Fläskfilé Black & White
      <br />
      Stekt rödspätta med pepparrotskräm
      <br />
      Pasta med bacon & tomatsås
    </p>

    <p>
      <b>Veckans vegetariska</b>
      <br />
      Pasta med soltorkade tomater, champinjoner & pesto
    </p>

    <p>
      <em>inkl. sallad, hembakat bröd, dryck samt kaffe/te och kaka</em>
      <br />
      Avhämtning: 99 kr
    </p>
  </div>
)

export default Lunch2
